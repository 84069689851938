<script>
import IdFromIri from '@/services/IdFromIri'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import BatteryStatus from './BatteryStatus.vue'
import SelectApiClientType from '@/api/SelectApiClientType'
export default {
    components: { BatteryStatus, EntitySelectInput },

    props: {
        model: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            changingRestaurant: false,
        }
    },

    computed: {
        selectApiClientType: () => SelectApiClientType,

        batteryIcon() {
            if (this.model?.battery > 90) return 'battery'
            else if (this.model?.battery > 80) return 'battery_alert'
            else if (this.model?.battery > 70) return 'battery_80'
            else return 'battery_unknown'
        },

        timestamp() {
            if (this.model.timestamp) {
                return this.$date.unix(this.model.timestamp).fromNow()
            } else return '-'
        },

        user() {
            return this.model.user
                ? [this.model.user.firstName, this.model.user.lastName].join(
                      ' '
                  )
                : '-'
        },

        restaurant() {
            return this.model.restaurant ? this.model.restaurant.name : '-'
        },

        restaurantId: {
            get() {
                return this.model.restaurant_id
                    ? `/api/v1/restaurants/${this.model.restaurant_id}`
                    : null
            },

            set(v) {
                const realId = IdFromIri.getId(v)
                this.setValue('restaurant_id', realId ? parseInt(realId) : null)
                this.changingRestaurant = false
            },
        },
    },

    methods: {
        setValue(key, value = undefined) {
            this.$store.dispatch('firebase/setConfig', {
                key: key,
                id: this.model.id,
                value: value,
            })
        },
    },
}
</script>

<template lang="pug">
v-card(outlined)
    v-sheet.pa-2.d-flex.justify-space-between.align-center(color="g-e8" outlined)
        .font-weight-bold.text-truncate.pl-2
            .name {{ model.id }}
        .text-right.mr-2
            v-chip(small color="green" v-if="model.status === 'online'") {{ $t('tablets.status.online') }}
            v-chip(small color="red" v-else-if="model.status === 'offline'") {{ $t('tablets.status.offline') }}
            v-chip(small v-else) {{ $t('tablets.status.unknown') }}
    v-card-text.base-width
        .d-flex.justify-space-between.align-center
            .key {{ $t('tablets.fields.app_version') }}
            .font-weight-bold {{ model.app_version || '-' }}
        .d-flex.justify-space-between.align-center
            .key {{ $t('tablets.fields.restaurant') }}
            .d-flex.align-center
                template(v-if="!changingRestaurant")
                    .font-weight-bold.flex-grow {{ restaurant }}
                    button.btn(@click="changingRestaurant = true")
                        v-icon edit

                template(v-else)
                    entity-select-input(
                        v-model="restaurantId"
                        name="adminOfRestaurant"
                        label="labels.admin_of_the_restaurant"
                        :entity="selectApiClientType.RESTAURANT"
                        autocomplete
                    )
        .d-flex.justify-space-between.align-center
            .key {{ $t('tablets.fields.user') }}
            .font-weight-bold {{ user }}
        .d-flex.justify-space-between.align-center
            .key {{ $t('tablets.fields.pages_to_print') }}
            .val
                v-text-field(single-line dense type="number" :value="model.pages || 0" @input="setValue('pages', parseInt($event))" style="width: 4em;")
        .d-flex.justify-space-between.align-center
            .key {{ $t('tablets.fields.auto_accept_enabled') }}
            .val
                v-switch(dense single-line :input-value="model.auto_accept_enabled" @change="setValue('auto_accept_enabled', !model.auto_accept_enabled)")
        .d-flex.align-center
            battery-status(:percent="model.battery || 0" :class="model.battery > 75 ? 'green--text' : model.battery > 33 ? 'yellow--text' : 'red--text'")
            .text-right(style="white-space: nowrap;")
                .font-weight-bold {{ timestamp }}
</template>
