<script>
export default {
    props: {
        percent: {
            type: null,
            default: null,
        },
    },

    computed: {
        fillWidth() {
            if (this.percent) {
                return 190 * (this.percent / 100)
            }
            return 0
        },
    },
}
</script>

<template lang="pug">
.battery(:title="`${percent} %`")
    svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.13 142.85")
        defs
        path.cls-1(d="M257.13,92.85a14.24,14.24,0,0,1-14.29,14.29V125A17.91,17.91,0,0,1,225,142.85H17.86A17.91,17.91,0,0,1,0,125V17.86A17.91,17.91,0,0,1,17.86,0H225a17.91,17.91,0,0,1,17.86,17.86V35.71A14.24,14.24,0,0,1,257.13,50Zm-14.29,0V50H228.56V17.86A3.53,3.53,0,0,0,225,14.29H17.86a3.52,3.52,0,0,0-3.57,3.57V125a3.52,3.52,0,0,0,3.57,3.57H225a3.53,3.53,0,0,0,3.57-3.57V92.85Z")
        rect.power(x=26 y=26 :width="fillWidth" height=90)
    | {{ percent }} %
</template>

<style lang="scss">
.battery {
    height: auto;
    width: 100%;
    font-weight: bold;

    & svg {
        width: 100%;
        max-width: 1.25em;
        fill: CurrentColor;
        margin-right: 0.25em;

        .power {
            fill: CurrentColor;
        }
    }
}
</style>
