<script>
import PageTitle from '@/components/layout/components/pageTitle'
import SearchBox from '@/components/filter/SearchBox'
import TabletCard from '@/components/elements/TabletCard'
import { mapGetters } from 'vuex'
export default {
    components: { PageTitle, SearchBox, TabletCard },

    data() {
        return {
            filters: {
                _search: undefined,
            },
        }
    },

    computed: {
        ...mapGetters({ tabletClients: 'firebase/tabletClients' }),

        showOffline: {
            get() {
                return this.$route.query.show_offline == 'true'
            },

            set(v) {
                this.$router.replace({
                    query: { ...this.$route.query, show_offline: v },
                })
            },
        },

        statusFilteredClients() {
            return this.tabletClients.filter(
                (el) =>
                    (!this.showOffline && el.status === 'online') ||
                    this.showOffline
            )
        },

        filteredClients() {
            if (this.filters?._search?.value) {
                return this.statusFilteredClients.filter((el) => {
                    return el.id.includes(this.filters._search.value)
                })
            }
            return this.statusFilteredClients
        },
    },

    mounted() {
        try {
            this.$store.dispatch('firebase/init')
            // eslint-disable-next-line no-empty
        } catch (e) {}
    },

    methods: {
        setFilter() {
            this.showOffline = !this.showOffline
        },
    },
}
</script>

<template lang="pug">
div
    page-title {{ $t('tablets.page_title') }}

    v-container(align-center)
        v-row.table-filter-row.align-center
            v-col.input-col(cols="auto")
                search-box(:label="$t('labels.search')" v-model="filters")
            v-col(cols="auto")
                v-chip(
                    :input-value="showOffline"
                    class="mr-1"
                    filter
                    @click="setFilter"
                ) {{ $t('tablets.show_offline') }}

        transition-group.row.mt-4(name="fade")
            v-col(v-for="tablet in filteredClients" :key="tablet.id")
                tablet-card(:model="tablet")
</template>
